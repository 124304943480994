//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { getUserFromCookie } from '@/utils/cookies.js';
export default {
	filters: {
		comma(val) {
			return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
		},
	},
	data() {
		return {
			id: 0,
			itemId: 0,
			totalPrice: 0,
			itemList: [],
			cnt: 1,
			optionColor: '',
			optionSize: null,
			selectedOptionSizes: [],
			firstVInput: null,
			baseUrl: `${process.env.baseURL}api/admin/items/displayFile?fileName=`,
		};
	},
	computed: {
		...mapGetters('cart', ['isModifyCart']),
		...mapGetters('product', ['isProductDetail']),
	},
	methods: {
		...mapActions('cart', ['updateCart', 'deleteCartItem']),
		commaSplit(val) {
			return val.split(',');
		},
		checkSameOption(element) {
			if (element.itemColor === this.optionColor && element.itemSize === this.optionSize) {
				return true;
			}
		},

		// 색상과 옵션은 상품상세보기의 itemOption에서 가져오기
		// 상품 옵션 목록에서 색상값 가져오기
		getOptionListColor() {
			const filteredData = this.isProductDetail.itemOption.filter(item => item.active === 'Y');
			const colors = filteredData.reduce((acc, item) => {
				const existingItem = acc.find(group => group.color === item.color);
				if (existingItem) {
					existingItem.count += 1;
				} else {
					// acc.push({ color: item.color, count: 1 });
					acc.push(item.color);
				}
				return acc;
			}, []);
			return colors;
		},
		// 색상 선택시 색상의 품절 목록 가져오기
		handleColorChange() {
			// 1117463 / 1117647 테스트
			this.optionSize = null; // 사이즈 초기화
			this.selectedOptionSizes = this.isProductDetail.itemOption
				.filter(item => item.active === 'Y' && item.color === this.optionColor)
				.map(item => {
					if (item.stock === '품절') {
						return `${item.size}(${item.stock})`;
					} else {
						return item.size;
					}
				});
		},
		onAddItem(event) {
			// console.log(this.isModifyCart.cartItems.some(this.checkSameOption));
			if (this.optionColor === '') {
				alert('색상을 선택하시기 바랍니다.');
				return false;
			}
			if (this.optionSize !== null) {
				if (this.isModifyCart.cartItems.some(this.checkSameOption)) {
					alert('이미 선택한 옵션입니다.');
					this.optionSize = '';
					return false;
				} else if (this.optionSize.includes('(품절)')) {
					alert('품절인 옵션는 구매하실 수 없습니다.');
					this.handleColorChange();
					this.optionSize = '';
					return false;
				} else {
					const myItem0 = this.isModifyCart.cartItems[0];
					const myItemObject = {
						cartItemId: 0,
						cartId: myItem0.cartId,
						itemId: myItem0.itemId,
						itemName: myItem0.itemName,
						firstFile: myItem0.firstFile,
						itemColor: this.optionColor,
						itemSize: this.optionSize,
						itemPrice: myItem0.itemPrice,
						itemCount: 1,
					};
					this.$store.commit('cart/ADD_MODIFYCART', myItemObject);
				}
				// 옵션 선택 초기화
				this.optionColor = '';
				this.optionSize = '';
				this.$nextTick(() => {
					const el = this.$el.querySelector('.v-select');
					if (el) {
						el.blur();
					}
				});
			}
		},
		onDeleteCartItem(data) {
			// console.log('onDeleteCartItem(수정다이얼로그)=', data.cartItemId);
			const modData = JSON.parse(JSON.stringify(this.isModifyCart));
			modData.cartItems = modData.cartItems.filter(item => item.cartItemId !== data.cartItemId);
			// totalPrice 갱신
			let totalPrice = 0;
			modData.cartItems.forEach(item => {
				totalPrice += item.itemCount * item.itemPrice;
			});
			modData.totalPrice = totalPrice;
			this.$store.commit('cart/SET_MODIFYCART', modData);
			this.deleteCartItem(data);
		},
		async updateData() {
			this.itemList.splice(0);
			this.totalPrice = 0;
			let myItemId = 0;
			for (let i = 0; i < this.isModifyCart.cartItems.length; i++) {
				const aItem = this.isModifyCart.cartItems[i];
				myItemId = aItem.itemId;
				const aItemObject = {
					cartItemId: aItem.cartItemId,
					itemId: aItem.itemId,
					itemColor: aItem.itemColor,
					itemSize: aItem.itemSize,
					itemPrice: aItem.itemPrice,
					itemCount: aItem.itemCount,
				};
				this.itemList.push(aItemObject);
				this.totalPrice += parseInt(aItem.itemPrice) * parseInt(aItem.itemCount);
			}

			this.itemId = myItemId;
			this.id = this.isModifyCart.cartId;
			if (this.itemList.length > 0) {
				const { id, itemId, totalPrice, itemList } = this;
				const cartData = {
					id,
					itemId,
					totalPrice,
					itemList,
				};
				// console.log('updateCart==>', cartData);
				await this.updateCart(cartData);
				// this.itemList.splice(0);
				const params = {
					customerUser: this.isUsername || getUserFromCookie(),
					page: 0,
					size: 100,
				};
				await this.$store.dispatch('cart/getSearchCart', params);
				this.$emit('closeDialog');
			} else {
				alert('옵션을 선택하시기 바랍니다.');
			}
		},
		increment(idx) {
			let incval = parseInt(this.$refs['cnt' + idx][0].value) + 1;
			if (incval > 99) incval = 99;
			// this.$refs['cnt' + idx][0].value = incval;
			// store 수정 옵션 수량 변경
			const requestData = { idx, count: incval };
			this.$store.commit('cart/UPDATE_MODIFYCART', requestData);
		},
		decrement(idx) {
			let decval = parseInt(this.$refs['cnt' + idx][0].value) - 1;
			if (decval < 1) decval = 1;
			// store 수정 옵션 수량 변경
			const requestData = { idx, count: decval };
			this.$store.commit('cart/UPDATE_MODIFYCART', requestData);
		},
	},
};
