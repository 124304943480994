export default {
	// 마켓 이용권 Order 목록 Actions
	async getSearchCoupons({ commit }, param) {
		try {
			const { data } = await this.$couponApi.fetchSearchCoupons(param);
			commit('SET_DATA', data);
		} catch (error) {}
	},

	// 마켓 이용권 Order 목록 Actions
	async getSearchCouponOrder({ commit }, param) {
		try {
			const { data } = await this.$couponApi.fetchSearchCouponOrder(param);
			commit('SET_ORDER_DATA', data);
		} catch (error) {}
	},

	// 마켓 이용권 Order 목록 Actions
	async getSearchCouponOrderApproval({ commit }, param) {
		try {
			const { data } = await this.$couponApi.fetchSearchCouponOrder(param);
			commit('SET_ORDER_APPROVAL_DATA', data);
		} catch (error) {}
	},

	// 이용권으로 이미지 다운로드 내역
	async getSearchCouponUseHistory({ commit }, param) {
		try {
			const { data } = await this.$couponApi.fetchSearchCouponUseHistory(param);
			commit('SET_USEHISTORY_DATA', data);
		} catch (error) {}
	},
	// 이미지 다운로드 내역 저장
	async addCouponUseHistory({ commit }, param) {
		try {
			const { data } = await this.$couponApi.registerCouponUseHistoryByBody(param);
			commit('ADD_COUPON_USEHISTORY', data);
		} catch (error) {}
	},

	// 마켓 이용권 Order 저장
	async addCouponOrder({ commit }, param) {
		try {
			const { data } = await this.$couponApi.registerCouponOrderByBody(param);
			commit('ADD_COUPON_ORDER', data);
		} catch (error) {}
	},

	// 마켓 이용권 Order 취소
	async updateCouponOrder({ commit }, param) {
		try {
			await this.$couponApi.updateCouponOrderByBody(param);
		} catch (error) {}
	},
};
