//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapMutations, mapActions } from 'vuex';
import { shopMenu as menu } from './menus.js';
export default {
	data() {
		return {
			authChkDialog: false,
			searchDialog: false,
			searchGubun: '상품이름',
			drawer: null,
			menu,
			clickedMenu: '',
			selectedItem: 0,
			showSearch: false,
			searchText: '',
			menuClickCount: 0,
		};
	},
	head() {
		return {
			title: this.$store.getters['mall/isMallTitle'],
		};
	},
	computed: {
		...mapGetters('auth', ['isLoggedIn']),
		...mapGetters('app', ['isAlertDialogVisible', 'isSignInDialogVisible']),
		...mapGetters('cart', ['isCart']),
		...mapGetters('mall', ['isPartnerMallInfoData', 'isLogoImage']),
		...mapGetters('product', ['getWatchCount']),
	},

	mounted() {
		this.callAllCodes();
		const mKey = Object.keys(menu).filter(key => menu[key].link === this.$route.path);
		if (mKey.length > 0) {
			this.changeColor(menu[mKey].title, this.$route.path);
		} else if (this.$route.path === '/') {
			this.changeColor('홈', '/');
		}
	},
	methods: {
		...mapMutations('auth', ['LOGOUT']),
		...mapMutations('app', [
			'SET_ALERT_DIALOG_VISIBLE',
			'SET_SIGNIN_DIALOG_VISIBLE',
			'SET_CATEGORY_DIALOG_VISIBLE',
		]),
		...mapActions('code', ['getAllCodes']),
		...mapActions('category', ['getTreeCategory', 'getAllCategory']),
		changeColor(value, link) {
			if (!this.isLoggedIn) {
				if (value === '전체상품' || value === '홈') {
					this.authChkDialog = false;

					this.clickedMenu = value;
					this.menuClickCount++;
				} else {
					this.authChkDialog = true;
				}
			} else {
				this.clickedMenu = value;
				this.menuClickCount++;
			}

			// console.log(this.clickedMenu + '//' + this.menuClickCount);
			// if (value === '전체상품' || value === '홈') {
			// 	console.log(this.clickedMenu + '//' + this.menuClickCount);
			// 	// this.$router.push(link + '?a=' + this.menuClickCount);
			// } else if (!this.isLoggedIn) {
			// 	// alert('이용 권한이 없습니다.');
			// 	this.onSignInDialog();
			// }
		},
		logout() {
			// console.log('로그아웃 !!')
			this.LOGOUT();
			this.$router.push('/');
		},

		onAlertDialog() {
			this.SET_ALERT_DIALOG_VISIBLE(true);
		},
		onSignInDialog() {
			this.SET_SIGNIN_DIALOG_VISIBLE(true);
		},
		onCategoryDialog() {
			// this.callTreeCategory();
			this.callAllCategory();
			this.SET_CATEGORY_DIALOG_VISIBLE(true);
		},
		onSearchProducts() {
			if (this.searchText.length > 0) {
				this.showSearch = false;
				// Product Store 초기화
				this.$store.commit('product/SET_ALL_INIT_DATA', ''); // 상품 목록 및 페이지, 조건 reset
				this.$store.commit('product/SET_STOCK', '판매');
				if (this.searchGubun === '상품코드') {
					this.$store.commit('product/SET_ITEMID', this.searchText); // Store 상품코드 값 변경
				} else {
					this.$store.commit('product/SET_ITEMNAME', this.searchText); // Store 상품명 값 변경
				}
				// this.searchText = '';
				if (this.$route.path.includes('SearchProducts')) {
					this.changeColor('검색', '/shop/SearchProducts');
					const watchData = this.getWatchCount + 1;
					this.$store.commit('product/SET_WATCH_DATA', watchData);
					// this.$router.push('/shop/SearchProducts?search=' + this.searchText);
				} else {
					this.changeColor('검색', '/shop/SearchProducts');
					this.$router.push(
						'/shop/SearchProducts?gubun=' + this.searchGubun + '&search=' + this.searchText,
					);
				}
			} else {
				alert('검색어를 입력하세요.');
			}
		},
		onSingup() {
			this.$router.push('/auth/SignUpPage');
		},
		onMypage() {
			this.$router.push('/app/OrderPage');
		},
		onCartPage() {
			this.$router.push('/app/CartPage');
		},
		onBoardPage() {
			this.$router.push('/cs/NoticePage');
		},
		onCouponPage() {
			this.$router.push('/shop/CouponPage');
		},
		async callAllCodes() {
			try {
				const response = await this.getAllCodes();
				console.log(response);
			} catch (error) {
				console.log('error ? ', error);
			}
		},
		// async callTreeCategory() {
		// 	try {
		// 		const response = await this.getTreeCategory();
		// 		console.log(response);
		// 	} catch (error) {
		// 		console.log('category error?', error);
		// 	}
		// },
		async callAllCategory() {
			try {
				const response = await this.getAllCategory();
				console.log(response);
			} catch (error) {
				console.log('category error?', error);
			}
		},
		goHome() {
			if (this.$route.path === '/') {
				window.location.reload();
			} else {
				this.$router.push('/');
				this.changeColor('홈', '/');
			}
		},
	},
};
