import { getUserFromCookie } from '@/utils/cookies.js';

export default {
	isLoggedIn(state) {
		if (process.client && typeof document !== 'undefined') {
			return !!state.token || getUserFromCookie();
		}
	},

	isLoggedInCheck(state) {
		if (state.token) {
			return true;
		} else {
			return false;
		}
		// return !!state.token || getUserFromCookie();
	},

	userToken(state) {
		return state.token;
	},

	isTermsAgree(state) {
		return state.termsAgree;
	},

	isPolicyAgree(state) {
		return state.policyAgree;
	},

	isConfirmAgree(state) {
		return state.confirmAgree;
	},

	isRegistrated(state) {
		return state.isRegistrated;
	},

	isUsername(state) {
		return state.username || getUserFromCookie();
	},
	isDeliveryCharge(state) {
		return state.deliveryCharge;
	},
	isCommissionRate(state) {
		return state.commissionRate;
	},
	isBalanceAmount(state) {
		return state.balanceAmount;
	},

	isCustomerShop(state) {
		return state.customerShop;
	},
};
