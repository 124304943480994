export const auth = axios => ({
	// 로그인
	loginUser(data) {
		return axios.post('api/retailer/auth/login', data);
	},

	// 회원가입(소매상)
	signupCustomerUser(data) {
		return axios.post('api/retailer/auth/signup', data);
	},

	// 회원정보 가져오기 (소매상)
	fetchCustomerShop(data) {
		return axios.get('api/retailer/auth/customershops', data);
	},

	// 회원정보 수정 (소매상)
	updateCustomerShopByBody(params) {
		return axios.put('api/retailer/auth/customershops', params);
	},
	// 비밀번호 변경
	updatePasswordByBody(params) {
		return axios.post('api/retailer/user/password', params);
	},
	// 소매상 첨부파일 저장
	registerCustomerFileByBody(params) {
		return axios.post('api/retailer/auth/customershops/file', params);
	},
	// 소매상 파일 삭제
	deleteCustomerFilePost(params) {
		return axios.delete('api/retailer/customer/file', params);
	},
});
