export default {
	// 주문 내역
	isOrder(state) {
		return state.orders;
	},
	isTotalPages(state) {
		return state.totalPages;
	},
	isPageSize(state) {
		return state.setPageSize;
	},
	// 대량 주문 내역
	isOrderBulk(state) {
		return state.ordersBulk;
	},
};
