export default {
	// 마켓 이용권 목록
	SET_DATA: (state, data) => {
		// ES6 ... spread operator (전개연산자) : 배열의 원소들을 분해해서 개별요소 리턴
		state.coupons = data.data;
		state.page = data.page.currentPage;
		state.totalPages = data.page.totalPages;
		state.totalItems = data.page.totalItems;
	},

	// 마켓 이용권 Order 목록
	SET_ORDER_DATA: (state, data) => {
		state.couponOrder = data.data;
		state.page = data.page.currentPage;
		state.totalPages = data.page.totalPages;
		state.totalItems = data.page.totalItems;
	},

	// 마켓 이용권 Order 승인 목록
	SET_ORDER_APPROVAL_DATA: (state, data) => {
		const today = new Date();
		state.couponOrderApproval = data.data.filter(t => new Date(t.endDate) >= today);
	},

	// 이미지 다운로드 내역
	SET_USEHISTORY_DATA: (state, data) => {
		state.couponUseHistory = data.data;
		state.page = data.page.currentPage;
		state.totalPages = data.page.totalPages;
		state.totalItems = data.page.totalItems;
	},
	// 마켓 이용권 Order 등록
	ADD_COUPON_USEHISTORY: (state, data) => {
		state.couponUseHistory.push(data);
	},

	// 마켓 이용권 Order 등록
	ADD_COUPON_ORDER: (state, data) => {
		state.couponOrder.push(data);
	},
	// 마켓 이용권 Order 취소
	UPDATE_ORDER_STATUS: (state, order) => {
		const idx = state.couponOrder.findIndex(t => t.orderId === order.orderId);
		if (idx !== -1) state.couponOrder.splice(idx, 1);
	},
};
