import { render, staticRenderFns } from "./ProductDetailDialog.vue?vue&type=template&id=60ffa61e&scoped=true&"
import script from "./ProductDetailDialog.vue?vue&type=script&lang=js&"
export * from "./ProductDetailDialog.vue?vue&type=script&lang=js&"
import style0 from "./ProductDetailDialog.vue?vue&type=style&index=0&id=60ffa61e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60ffa61e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBtn,VCard,VCardText,VCardTitle,VDialog,VIcon,VSpacer,VToolbar,VToolbarTitle})


/* vuetify-loader */
import installDirectives from "!../../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Scroll from 'vuetify/lib/directives/scroll'
installDirectives(component, {Scroll})
