// CATEGORY Vuex Actions

export default {
	// Category 전체 조회 Actions
	async getAllCategory({ commit }, param) {
		try {
			const { data } = await this.$categoryApi.fetchListAndTreeCategory(param);
			commit('SET_DATA', data);
		} catch (error) {}
	},

	// Category Modal 조회 Actions
	async getModalCategory({ commit }, param) {
		try {
			const { data } = await this.$categoryApi.fetchTreeCategory(param);
			commit('SET_MODAL_DATA', data);
		} catch (error) {}
	},

	// Category Tree 조회 Actions
	async getTreeCategory({ commit }, param) {
		try {
			const { data } = await this.$categoryApi.fetchTreeCategory(param);
			commit('SET_TREE_DATA', data);
		} catch (error) {}
	},
};
