//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapMutations } from 'vuex';
export default {
	data() {
		return {
			// sign in buttons
			isLoading: false,
			isSignInDisabled: false,

			// form
			isFormValid: true,
			login: {
				username: '',
				password: '',
			},

			logMessage: '',

			// form error
			error: false,
			errorMessages: '',
			errorProvider: false,
			errorProviderMessages: '',

			// show password field
			showPassword: false,

			// input rules
			rules: {
				required: value => (value && Boolean(value)) || '입력된 값이 없습니다',
			},
		};
	},
	computed: {},
	watch: {
		isFormValid(val) {
			console.log('Form에 변화 있습니까?', val);
		},
	},
	mounted() {
		// console.log('mounted ?? ');
	},

	methods: {
		...mapActions('auth', ['LOGIN']),
		...mapMutations('auth', ['LOGOUT']),
		...mapMutations('app', ['SET_SIGNIN_DIALOG_VISIBLE']),
		inVisibleSignInDialog() {
			this.SET_SIGNIN_DIALOG_VISIBLE(false);
			this.isLoading = false;
		},
		// 로그인 버튼 클릭
		async submit() {
			if (this.$refs.form.validate()) {
				await this.LOGOUT();

				this.isLoading = true;
				this.isSignInDisabled = true;
				this.signIn();
			}
		},

		// 로그인 Api 호출 (반드시 store의 Action에서 호출한다...일관성 유지!!!)
		async signIn() {
			try {
				// console.log('로그인 시도한다');
				// eslint-disable-next-line no-unused-vars
				const response = await this.LOGIN({
					username: this.login.username,
					password: this.login.password,
				});
				// bus.$emit('show:toast', response.data.message)
				this.$router.push(this.$route.path);
				this.initForm();
				this.isLoading = false;
				this.isSignInDisabled = false;
				// this.resetForm();
				this.SET_SIGNIN_DIALOG_VISIBLE(false);
			} catch (error) {
				if (error.response.data.code === 'USERNAME_NOT_FOUND') {
					this.logMessage =
						error.response.data.message +
						'<div class="subtitle-2">※ 회원가입하셨다면 관리자에게 승인 요청해 주세요.</div>';
				} else {
					this.logMessage = error.response.data.message;
				}
				this.isLoading = false;
				this.isSignInDisabled = false;
				// this.resetForm();
			}
			// this.$router.push('/')
		},

		// 회원 가입
		singup() {
			this.$router.push('/auth/SignUpPage');
			this.SET_SIGNIN_DIALOG_VISIBLE(false);
		},

		// Home 이동
		goHome() {
			this.$router.push('/');
			this.SET_SIGNIN_DIALOG_VISIBLE(false);
		},

		// Input 초기화
		initForm() {
			this.login.username = '';
			this.login.password = '';
		},

		resetErrors() {
			this.error = false;
			this.errorMessages = '';

			this.errorProvider = false;
			this.errorProviderMessages = '';
		},

		// Form 초기화
		resetForm() {
			this.$refs.form.resetValidation();
		},
	},
};
