export const balance = axios => ({
	// Balance 조회
	fetchSearchBalance(params) {
		return axios.get('/api/retailer/balance/search', { params });
	},

	// 정산 합계
	fetchBalanceSum(params) {
		return axios.get('/api/retailer/balance/sum', { params });
	},
});
