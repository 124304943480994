import getters from './getters';
import actions from './actions';
import mutations from './mutations';

const state = () => ({
	watchCount: 0,
	// 상품 리스트
	products: [],
	// 상품 상세
	productDetail: {},
	productDetailOption: [],
	productOrderCount: 0,
	productOrderSum: 0,

	pickitemBoolean: false,
	regularBoolean: false,
	regularStore: '',
	// 검색 조건 파라미터
	page: 0,
	setPageSize: 24,
	totalPages: 0,
	totalItems: 0,
	searchCondition: {
		stock: '', // 디디엠 품절
		itemId: '',
		itemName: '',
		categoryPath: '',
		categoryPathName: '',
		createStartDate: '',
		createEndDate: '',
		modifyStartDate: '',
		modifyEndDate: '',
		stockStartDate: '',
		stockEndDate: '',
		startStandardPrice: '',
		endStandardPrice: '',
		partnerShopId: '',
		partnerShopName: '',
		partnerShopIds: [],
		itemStyle: '',
	},
});

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
