export const shopMenu = [
	// {
	// 	title: '홈',
	// 	link: '/',
	// },
	{
		title: '전체상품',
		link: '/shop/AllProducts',
	},
	{
		title: '스타일신상',
		link: '/shop/StyleProducts',
	},
	{
		title: '오늘의신상',
		link: '/shop/TodayProducts',
	},
	{
		title: '단골신상',
		link: '/shop/RegularProducts',
	},
	{
		title: '상가별신상',
		link: '/shop/StreetProducts',
	},
	{
		title: '베스트신상',
		link: '/shop/BestProducts',
	},
	{
		title: 'SALE상품',
		link: '/shop/SaleProducts',
	},
	{
		title: '품절상품',
		link: '/shop/SoldoutProducts',
	},
	// {
	// 	title: '찜한상품',
	// 	link: '/shop/PickProductsPage',
	// },
	// {
	// 	title: '단골브랜드',
	// 	link: '/shop/RegularListPage',
	// },
];

export const myMenu = [
	{
		title: '장바구니',
		link: '/app/CartPage',
	},
	{
		title: '주문배송',
		link: '/app/OrderPage',
	},
	{
		title: '찜한상품',
		link: '/app/PickPage',
	},
	{
		title: '단골매장',
		link: '/app/RegularPage',
	},
	{
		title: '회원첨부파일',
		link: '/app/AttachPage',
	},
	{
		title: '회원정보수정',
		link: '/app/InfoPage',
	},
	{
		title: '마켓이용권',
		link: '/app/CouponPage',
	},
	{
		title: '다운로드내역',
		link: '/app/HistoryPage',
	},
	{
		title: '회원탈퇴',
		link: '/app/DropPage',
	},
];

export const csMenu = [
	{
		title: '공지&이벤트',
		link: '/cs/NoticePage',
	},
	{
		title: 'Q&A',
		link: '/cs/QnaPage',
	},
	{
		title: '도매회원인증',
		link: '/cs/ApprovalPage',
	},
	{
		title: '세금계산서신청',
		link: '/cs/BillPage',
	},
	{
		title: '입점신청및업무제휴',
		link: '/cs/CooperPage',
	},
];

export default {
	myMenu,
	shopMenu,
	csMenu,
};
