// SHOP Vuex Actions

export default {
	// 상품 조회 Actions
	async getSearchProduct({ commit }, param) {
		try {
			param.sortBy = process.env.itemSortBy;
			param.sortOrder = process.env.itemSortOrder;
			param.ddmVisible = 'Y';
			// 디디엠마켓=ddmVisible, 도매상몰=partnerVisible
			// 도매상몰은 params.partnerShopId=proccess.env.partnerShopId
			// console.log('상품조회(actions)2==', param);
			const { data } = await this.$productApi.fetchSearchProducts(param);
			commit('SET_DATA', data);
		} catch (error) {}
	},
	// 상품 상세
	async getProductById({ commit }, id) {
		try {
			const { data } = await this.$productApi.fetchProductById(id);
			commit('SET_DETAIL_DATA', data);
		} catch (error) {}
	},
	// 상품 상세 promotion
	async getProductPromotionById({ commit }, id) {
		try {
			const { data } = await this.$productApi.fetchProductPromotionById(id);
			commit('SET_DETAIL_DATA', data);
		} catch (error) {}
	},
	// 베스트 상품 조회 Actions
	async getSearchItemBests({ commit }, param) {
		try {
			param.sortBy = process.env.sortBy;
			param.sortOrder = process.env.sortOrder;
			param.ddmVisible = 'Y';
			param.partnerMallId = process.env.partnerMallId;
			// 디디엠마켓=ddmVisible, 도매상몰=partnerVisible
			// 도매상몰은 params.partnerShopId=proccess.env.partnerShopId
			// console.log('상품조회(actions)2==', param);
			const { data } = await this.$productApi.fetchSearchItemBests(param);
			commit('SET_DATA', data);
		} catch (error) {}
	},
	// SALE 상품 조회 Actions
	async getSearchItemSales({ commit }, param) {
		try {
			param.sortBy = process.env.sortBy;
			param.sortOrder = process.env.sortOrder;
			param.ddmVisible = 'Y';
			param.partnerMallId = process.env.partnerMallId;
			// 디디엠마켓=ddmVisible, 도매상몰=partnerVisible
			// 도매상몰은 params.partnerShopId=proccess.env.partnerShopId
			// console.log('상품조회(actions)2==', param);
			const { data } = await this.$productApi.fetchSearchItemSales(param);
			commit('SET_DATA', data);
		} catch (error) {}
	},
};
