export const coupon = axios => ({
	// 마켓 이용권 조회
	fetchSearchCoupons(params) {
		return axios.get('/api/retailer/coupons/search', { params });
	},
	// 마켓 이용권 Order 조회
	fetchSearchCouponOrder(params) {
		return axios.get('/api/retailer/couponorder/search', { params });
	},
	// 마켓 이용권 Order 저장
	registerCouponOrderByBody(params) {
		return axios.post('/api/retailer/couponorder', params);
	},
	// 마켓 이용권 Order 취소
	updateCouponOrderByBody(params) {
		return axios.put(`/api/retailer/couponorder/status/${params.id}`, params);
	},
	// 이용권으로 이미지 다운로드 내역 조회
	registerCouponUseHistoryByBody(params) {
		return axios.post('/api/retailer/couponusehistory', params);
	},
	// 이용권으로 이미지 다운로드 내역 저장
	fetchSearchCouponUseHistory(params) {
		return axios.get('/api/retailer/couponusehistory/search', { params });
	},
});
