export const mall = axios => ({
	// Mall 메인화면 구성 가져오기
	fetchPartnerMallById(id) {
		return axios.get(`/api/retailer/malls/mallDetail/${id}`);
	},

	// 상가별 도매상 검색
	fetchSearchShops(params) {
		return axios.get('/api/retailer/malls/partnerShops/search', { params });
	},

	// 찜한 상품 저장
	registerPickItemByBody(params) {
		return axios.post('/api/retailer/pickItem', params);
	},
	// 찜한 상품 삭제
	deletePickItemByBody(id) {
		return axios.delete(`/api/retailer/pickItem/${id}`);
	},
	// 찜한 상품 조회
	fetchSearchPickItem(params) {
		return axios.get('/api/retailer/pickItem/search', params);
	},
	// 단골 매장 저장
	registerRegularStoreByBody(params) {
		return axios.post('/api/retailer/regularStore', params);
	},
	// 단골 매장 삭제
	deleteRegularStoreByBody(id) {
		return axios.delete(`/api/retailer/regularStore/${id}`);
	},
	// 단골 매장 조회
	fetchSearchRegularStore(params) {
		return axios.get('/api/retailer/regularStore/search', params);
	},
});
