//
//
//
//
//
//
//
//
//
//
//
//
//

import SignInDialog from '@/components/ui/landing/signin/SignInDialog.vue';
import AlertDialog from '@/components/ui/landing/dialog/AlertDialog.vue';
import CsHeader from '@/components/ui/landing/header/CsHeader.vue';
import FabButton from '@/components/util/FabButton';
import DefaultFooter from '@/components/ui/landing/footer/DefaultFooter.vue';
import ProductDetailDialog from '@/components/ui/landing/dialog/ProductDetailDialog.vue';

export default {
	components: {
		CsHeader,
		FabButton,
		AlertDialog,
		DefaultFooter,
		SignInDialog,
		ProductDetailDialog,
	},
	async mounted() {
		// 도매상 정보 가져오기
		const mallId = process.env.partnerMallId;
		await this.$store.dispatch('mall/getPartnerMallById', mallId);
	},
};
