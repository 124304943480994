export default {
	// Balance 목록 Actions
	async getSearchBalance({ commit }, param) {
		try {
			const { data } = await this.$balanceApi.fetchSearchBalance(param);
			// const sortData = data.sort((a, b) => new Date(a.balanceDate) - new Date(b.balanceDate));
			commit('SET_DATA', data);
		} catch (error) {}
	},
	// Balance 합계
	async getSearchBalanceSum({ commit }, param) {
		try {
			const { data } = await this.$balanceApi.fetchBalanceSum(param);
			commit('SET_SUM_DATA', data);
		} catch (error) {}
	},
};
