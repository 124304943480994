import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7cb59b74 = () => interopDefault(import('..\\src\\pages\\500.vue' /* webpackChunkName: "pages/500" */))
const _f2a2271c = () => interopDefault(import('..\\src\\pages\\c2hvd0l0ZW1EYXRh.vue' /* webpackChunkName: "pages/c2hvd0l0ZW1EYXRh" */))
const _0b642b79 = () => interopDefault(import('..\\src\\pages\\Error.vue' /* webpackChunkName: "pages/Error" */))
const _ed73eaba = () => interopDefault(import('..\\src\\pages\\ItemDetail.vue' /* webpackChunkName: "pages/ItemDetail" */))
const _3c626a86 = () => interopDefault(import('..\\src\\pages\\app\\AttachPage.vue' /* webpackChunkName: "pages/app/AttachPage" */))
const _ef46fa3e = () => interopDefault(import('..\\src\\pages\\app\\CartPage.vue' /* webpackChunkName: "pages/app/CartPage" */))
const _05b2e367 = () => interopDefault(import('..\\src\\pages\\app\\CouponPage.vue' /* webpackChunkName: "pages/app/CouponPage" */))
const _ec27371c = () => interopDefault(import('..\\src\\pages\\app\\Dashboard.vue' /* webpackChunkName: "pages/app/Dashboard" */))
const _454ff7b0 = () => interopDefault(import('..\\src\\pages\\app\\DropPage.vue' /* webpackChunkName: "pages/app/DropPage" */))
const _48dabbbe = () => interopDefault(import('..\\src\\pages\\app\\HistoryPage.vue' /* webpackChunkName: "pages/app/HistoryPage" */))
const _25480322 = () => interopDefault(import('..\\src\\pages\\app\\InfoPage.vue' /* webpackChunkName: "pages/app/InfoPage" */))
const _f6a62e68 = () => interopDefault(import('..\\src\\pages\\app\\OrderDetailPage.vue' /* webpackChunkName: "pages/app/OrderDetailPage" */))
const _5362245b = () => interopDefault(import('..\\src\\pages\\app\\OrderPage.vue' /* webpackChunkName: "pages/app/OrderPage" */))
const _0cd1f6e2 = () => interopDefault(import('..\\src\\pages\\app\\PickPage.vue' /* webpackChunkName: "pages/app/PickPage" */))
const _aa6555ae = () => interopDefault(import('..\\src\\pages\\app\\RegularPage.vue' /* webpackChunkName: "pages/app/RegularPage" */))
const _e5058f90 = () => interopDefault(import('..\\src\\pages\\auth\\SignInPage.vue' /* webpackChunkName: "pages/auth/SignInPage" */))
const _297763ae = () => interopDefault(import('..\\src\\pages\\auth\\SignUpPage.vue' /* webpackChunkName: "pages/auth/SignUpPage" */))
const _6251fc81 = () => interopDefault(import('..\\src\\pages\\cs\\ApprovalPage.vue' /* webpackChunkName: "pages/cs/ApprovalPage" */))
const _0d8b09c5 = () => interopDefault(import('..\\src\\pages\\cs\\BillPage.vue' /* webpackChunkName: "pages/cs/BillPage" */))
const _570c8490 = () => interopDefault(import('..\\src\\pages\\cs\\CooperPage.vue' /* webpackChunkName: "pages/cs/CooperPage" */))
const _2d2c39d6 = () => interopDefault(import('..\\src\\pages\\cs\\NoticePage.vue' /* webpackChunkName: "pages/cs/NoticePage" */))
const _ea55a458 = () => interopDefault(import('..\\src\\pages\\cs\\QnaPage.vue' /* webpackChunkName: "pages/cs/QnaPage" */))
const _415d9fe0 = () => interopDefault(import('..\\src\\pages\\shop\\AllProducts.vue' /* webpackChunkName: "pages/shop/AllProducts" */))
const _3e0410bd = () => interopDefault(import('..\\src\\pages\\shop\\BestProducts.vue' /* webpackChunkName: "pages/shop/BestProducts" */))
const _48870624 = () => interopDefault(import('..\\src\\pages\\shop\\CartPage.vue' /* webpackChunkName: "pages/shop/CartPage" */))
const _8db18b52 = () => interopDefault(import('..\\src\\pages\\shop\\CategoryProducts.vue' /* webpackChunkName: "pages/shop/CategoryProducts" */))
const _6549a1ea = () => interopDefault(import('..\\src\\pages\\shop\\CouponPage.vue' /* webpackChunkName: "pages/shop/CouponPage" */))
const _6db36631 = () => interopDefault(import('..\\src\\pages\\shop\\OrderEndPage.vue' /* webpackChunkName: "pages/shop/OrderEndPage" */))
const _ceefed10 = () => interopDefault(import('..\\src\\pages\\shop\\OrderPage.vue' /* webpackChunkName: "pages/shop/OrderPage" */))
const _05929bdb = () => interopDefault(import('..\\src\\pages\\shop\\RegularProducts.vue' /* webpackChunkName: "pages/shop/RegularProducts" */))
const _aaef82c0 = () => interopDefault(import('..\\src\\pages\\shop\\SaleProducts.vue' /* webpackChunkName: "pages/shop/SaleProducts" */))
const _5e831f41 = () => interopDefault(import('..\\src\\pages\\shop\\SearchProducts.vue' /* webpackChunkName: "pages/shop/SearchProducts" */))
const _ad050d8e = () => interopDefault(import('..\\src\\pages\\shop\\SoldoutProducts.vue' /* webpackChunkName: "pages/shop/SoldoutProducts" */))
const _74e2591c = () => interopDefault(import('..\\src\\pages\\shop\\StreetProducts.vue' /* webpackChunkName: "pages/shop/StreetProducts" */))
const _3c3461a0 = () => interopDefault(import('..\\src\\pages\\shop\\StyleProducts.vue' /* webpackChunkName: "pages/shop/StyleProducts" */))
const _596b78c0 = () => interopDefault(import('..\\src\\pages\\shop\\TodayProducts.vue' /* webpackChunkName: "pages/shop/TodayProducts" */))
const _502bed63 = () => interopDefault(import('..\\src\\pages\\index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/500",
    component: _7cb59b74,
    name: "500"
  }, {
    path: "/c2hvd0l0ZW1EYXRh",
    component: _f2a2271c,
    name: "c2hvd0l0ZW1EYXRh"
  }, {
    path: "/Error",
    component: _0b642b79,
    name: "Error"
  }, {
    path: "/ItemDetail",
    component: _ed73eaba,
    name: "ItemDetail"
  }, {
    path: "/app/AttachPage",
    component: _3c626a86,
    name: "app-AttachPage"
  }, {
    path: "/app/CartPage",
    component: _ef46fa3e,
    name: "app-CartPage"
  }, {
    path: "/app/CouponPage",
    component: _05b2e367,
    name: "app-CouponPage"
  }, {
    path: "/app/Dashboard",
    component: _ec27371c,
    name: "app-Dashboard"
  }, {
    path: "/app/DropPage",
    component: _454ff7b0,
    name: "app-DropPage"
  }, {
    path: "/app/HistoryPage",
    component: _48dabbbe,
    name: "app-HistoryPage"
  }, {
    path: "/app/InfoPage",
    component: _25480322,
    name: "app-InfoPage"
  }, {
    path: "/app/OrderDetailPage",
    component: _f6a62e68,
    name: "app-OrderDetailPage"
  }, {
    path: "/app/OrderPage",
    component: _5362245b,
    name: "app-OrderPage"
  }, {
    path: "/app/PickPage",
    component: _0cd1f6e2,
    name: "app-PickPage"
  }, {
    path: "/app/RegularPage",
    component: _aa6555ae,
    name: "app-RegularPage"
  }, {
    path: "/auth/SignInPage",
    component: _e5058f90,
    name: "auth-SignInPage"
  }, {
    path: "/auth/SignUpPage",
    component: _297763ae,
    name: "auth-SignUpPage"
  }, {
    path: "/cs/ApprovalPage",
    component: _6251fc81,
    name: "cs-ApprovalPage"
  }, {
    path: "/cs/BillPage",
    component: _0d8b09c5,
    name: "cs-BillPage"
  }, {
    path: "/cs/CooperPage",
    component: _570c8490,
    name: "cs-CooperPage"
  }, {
    path: "/cs/NoticePage",
    component: _2d2c39d6,
    name: "cs-NoticePage"
  }, {
    path: "/cs/QnaPage",
    component: _ea55a458,
    name: "cs-QnaPage"
  }, {
    path: "/shop/AllProducts",
    component: _415d9fe0,
    name: "shop-AllProducts"
  }, {
    path: "/shop/BestProducts",
    component: _3e0410bd,
    name: "shop-BestProducts"
  }, {
    path: "/shop/CartPage",
    component: _48870624,
    name: "shop-CartPage"
  }, {
    path: "/shop/CategoryProducts",
    component: _8db18b52,
    name: "shop-CategoryProducts"
  }, {
    path: "/shop/CouponPage",
    component: _6549a1ea,
    name: "shop-CouponPage"
  }, {
    path: "/shop/OrderEndPage",
    component: _6db36631,
    name: "shop-OrderEndPage"
  }, {
    path: "/shop/OrderPage",
    component: _ceefed10,
    name: "shop-OrderPage"
  }, {
    path: "/shop/RegularProducts",
    component: _05929bdb,
    name: "shop-RegularProducts"
  }, {
    path: "/shop/SaleProducts",
    component: _aaef82c0,
    name: "shop-SaleProducts"
  }, {
    path: "/shop/SearchProducts",
    component: _5e831f41,
    name: "shop-SearchProducts"
  }, {
    path: "/shop/SoldoutProducts",
    component: _ad050d8e,
    name: "shop-SoldoutProducts"
  }, {
    path: "/shop/StreetProducts",
    component: _74e2591c,
    name: "shop-StreetProducts"
  }, {
    path: "/shop/StyleProducts",
    component: _3c3461a0,
    name: "shop-StyleProducts"
  }, {
    path: "/shop/TodayProducts",
    component: _596b78c0,
    name: "shop-TodayProducts"
  }, {
    path: "/",
    component: _502bed63,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
