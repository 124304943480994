import { auth } from '@/api/auth';
import { code } from '@/api/code';
import { mall } from '@/api/mall';
import { category } from '@/api/category';
import { product } from '@/api/product';
import { cart } from '@/api/cart';
import { order } from '@/api/order';
import { balance } from '@/api/balance';
import { post } from '@/api/post';
import { coupon } from '@/api/coupon';

import { getAuthFromCookie } from '@/utils/cookies.js';

export default function ({ $axios, store, redirect, error: nuxtError }, inject) {
	$axios.setBaseURL(process.env.baseURL);
	// $axios.setHeader('Authorization', 'Bearer ' + token);
	// $axios.setToken(store.state.auth.token || getAuthFromCookie(), 'Bearer');

	const authApi = auth($axios);
	const codeApi = code($axios);
	const mallApi = mall($axios);
	const categoryApi = category($axios);
	const productApi = product($axios);
	const cartApi = cart($axios);
	const orderApi = order($axios);
	const balanceApi = balance($axios);
	const postApi = post($axios);
	const couponApi = coupon($axios);

	inject('authApi', authApi);
	inject('codeApi', codeApi);
	inject('mallApi', mallApi);
	inject('categoryApi', categoryApi);
	inject('productApi', productApi);
	inject('cartApi', cartApi);
	inject('orderApi', orderApi);
	inject('balanceApi', balanceApi);
	inject('postApi', postApi);
	inject('couponApi', couponApi);

	$axios.onError(error => {
		console.log('에러코드 >> ', error.response.status);
		if (error.response.status === 500) {
			redirect('/500');
		} else if (error.response.status === 404) {
			redirect('/404');
		} else if (error.response.status === 401) {
			store.commit('auth/LOGOUT');
			// 인증에 실패 한 경우 로그인 Dialog 띄운다
			store.commit('app/SET_SIGNIN_DIALOG_VISIBLE', true);
		} else {
			return Promise.reject(error);
		}
	});

	$axios.onRequest(config => {
		// check if the user is authenticated
		const acessToken = store.state.auth.token || getAuthFromCookie();
		// console.log('request 영역 진입 >>> ', 'requestUrl: ' + config.url + ' token: ' + acessToken);

		// set the Authorization header using the access token
		// if (acessToken) {
		// 	config.headers.Authorization = 'Bearer ' + acessToken;
		// }
		config.headers.Authorization = 'Bearer ' + acessToken;
		// config.headers.PartnerMallDomain = process.env.partnerMallDomain;
		config.headers.PartnerMallId = process.env.partnerMallId;

		return config;
	});

	$axios.interceptors.response.use(response => {
		// console.log('response 영역 진입 >>> ', response.request.responseURL);
		if (response.status === 200) {
			if (response.request.responseURL && response.request.responseURL.includes('login')) {
				console.log('로그인 성공 >>>', response);
				// store.dispatch("setUser", response);
			}
		}

		return response;
	});
}
