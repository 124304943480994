import getters from './getters';
import mutations from './mutations';
import actions from './actions';

const state = () => ({
	coupons: [],
	couponOrder: [],
	couponOrderApproval: [],
	couponUseHistory: [],
	page: 0,
	setPageSize: 100,
	totalPages: 0,
	totalItems: 0,
	// labels
	labels: [
		{
			id: 'ONE_MONTH',
			title: '1개월권',
			color: 'orange',
			num: 1,
		},
		{
			id: 'TWO_MONTH',
			title: '2개월권',
			color: 'blue',
			num: 2,
		},
		{
			id: 'THREE_MONTH',
			title: '3개월권',
			color: 'orange',
			num: 3,
		},
		{
			id: 'FOUR_MONTH',
			title: '4개월권',
			color: 'blue',
			num: 4,
		},
		{
			id: 'FIVE_MONTH',
			title: '5개월권',
			color: 'orange',
			num: 5,
		},
		{
			id: 'SIX_MONTH',
			title: '6개월권',
			color: 'blue',
			num: 6,
		},
		{
			id: 'SEVEN_MONTH',
			title: '7개월권',
			color: 'orange',
			num: 7,
		},
		{
			id: 'EIGHT_MONTH',
			title: '8개월권',
			color: 'blue',
			num: 8,
		},
		{
			id: 'NINE_MONTH',
			title: '9개월권',
			color: 'orange',
			num: 9,
		},
		{
			id: 'TEN_MONTH',
			title: '10개월권',
			color: 'blue',
			num: 10,
		},
		{
			id: 'ELEVEN_MONTH',
			title: '11개월권',
			color: 'orange',
			num: 11,
		},
		{
			id: 'TWELVE_MONTH',
			title: '12개월권',
			color: 'blue',
			num: 12,
		},
	],
});

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
