/*
|---------------------------------------------------------------------
| PRODUCT Vuex Mutations
|---------------------------------------------------------------------
*/
export default {
	// Product 검색 Or 전체 조회
	SET_DATA: (state, data) => {
		// console.log('server 에서 내려온 데이타(mutation) >> ', data)
		// console.log('==SET_DATA(mutations)=>', data);
		// ES6 ... spread operator (전개연산자) : 배열의 원소들을 분해해서 개별요소 리턴
		state.products.push(...data.data);
		state.page = data.page.currentPage;
		state.totalPages = data.page.totalPages;
		state.totalItems = data.page.totalItems;
	},
	// Product 1건 상세
	SET_DETAIL_DATA: (state, data) => {
		state.productDetail = data;
		// 상품 옵션 리스트
		state.productDetailOption = [];
		state.productOrderCount = 0;
		state.productOrderSum = 0;
		const filteredItems = data.itemOption.filter(
			item => item.active === 'Y' && item.stock === '판매',
		);
		state.productDetailOption = filteredItems.map((item, index) => ({
			...item,
			itemCount: 0,
			index,
		}));

		if (data.pickItemOneDto !== null) {
			state.pickitemBoolean = true;
		} else {
			state.pickitemBoolean = false;
		}
		if (data.regularStoreOneDto !== null) {
			state.regularBoolean = true;
		} else {
			state.regularBoolean = false;
		}
		// console.log('==SET_DETAIL_DATA1(mutations)=>', state.pickitemBoolean);
		// console.log('==SET_DETAIL_DATA2(mutations)=>', data);
	},
	// Product 옵션 리스트
	SET_OPTION_COUNT: (state, data) => {
		const itemPrice = state.productDetail.standardPrice;
		state.productDetailOption[data.index].itemCount = data.count;

		let cnt = 0;
		let sum = 0;
		for (let inx = 0; inx < state.productDetailOption.length; inx++) {
			const aItem = state.productDetailOption[inx];
			cnt += parseInt(aItem.itemCount);
			sum += parseInt(itemPrice) * parseInt(aItem.itemCount);
		}
		state.productOrderCount = cnt;
		state.productOrderSum = sum;
	},

	SET_PICKITEM_BOOLEAN(state, bool) {
		// console.log('==SET_PICKITEM_BOOLEAN(mutations)=>', bool);
		state.pickitemBoolean = bool;
	},
	SET_REGULAR_BOOLEAN(state, bool) {
		state.regularBoolean = bool;
	},

	SET_WATCH_DATA: (state, data) => {
		state.watchCount = data;
	},

	SET_PAGE_SIZE: (state, data) => {
		// console.log('mutation ? ', data)
		state.setPageSize = data;
	},
	// New Add
	SET_STOCK: (state, data) => {
		state.searchCondition.stock = data;
	},
	SET_CATEGORYPATH: (state, data) => {
		state.searchCondition.categoryPath = data.categoryPath;
		state.searchCondition.categoryPathName = data.categoryPathName;
	},
	// 상품명 검색 조건
	SET_ITEMID: (state, data) => {
		state.searchCondition.itemId = data;
	},
	// 상품명 검색 조건
	SET_ITEMNAME: (state, data) => {
		state.searchCondition.itemName = data;
	},
	// 등록일자 기간 검색
	SET_CREATEDATE: (state, data) => {
		state.searchCondition.createStartDate = data.startDate;
		state.searchCondition.createEndDate = data.endDate;
	},
	// 수정일자 기간 검색
	SET_MODIFYDATE: (state, data) => {
		state.searchCondition.modifyStartDate = data.startDate;
		state.searchCondition.modifyEndDate = data.endDate;
	},
	// 품절일자 기간 검색
	SET_STOCKDATE: (state, data) => {
		state.searchCondition.stockStartDate = data.startDate;
		state.searchCondition.stockEndDate = data.endDate;
	},
	// 기준가격 구간 검색
	SET_STANDARDPRICE: (state, data) => {
		state.searchCondition.startStandardPrice = data.startPrice;
		state.searchCondition.endStandardPrice = data.endPrice;
	},
	// 도매상아이디(코드) 검색 조건
	SET_PARTNERSHOPID: (state, data) => {
		state.searchCondition.partnerShopId = data;
	},
	// 도매상아이디(코드) 검색 조건
	SET_PARTNERSHOPNAME: (state, data) => {
		state.searchCondition.partnerShopName = data;
	},
	// 도매상들  검색 조건
	SET_PARTNERSHOPIDS: (state, data) => {
		state.searchCondition.partnerShopIds = data;
	},
	// 스타일 검색 조건 (like)
	SET_ITEMSTYLE: (state, data) => {
		state.searchCondition.itemStyle = data;
	},
	SET_PAGE: (state, data) => {
		state.page = data;
	},
	SET_INIT_DATA: (state, data) => {
		state.products = [];
		state.page = 0;
		state.totalPages = 0;
		state.totalItems = 0;
	},
	// 상품 검색을 위한 모든 조건 RESET
	SET_ALL_INIT_DATA: (state, data) => {
		state.products = [];
		state.page = 0;
		state.totalPages = 0;
		state.totalItems = 0;

		state.searchCondition.itemId = ''; // 상품코드
		state.searchCondition.itemName = ''; // 상품명
		state.searchCondition.categoryPath = ''; // 카테고리
		state.searchCondition.categoryPathName = ''; // 카테고리
		state.searchCondition.createStartDate = ''; // 상품 등록 일자 시작
		state.searchCondition.createEndDate = ''; // 상품 등록 일자 종료
		state.searchCondition.modifyStartDate = ''; // 상품 수정 일자 시작
		state.searchCondition.modifyEndDate = ''; // 상품 수정 일자 종료
		state.searchCondition.stockStartDate = ''; // 상품 픔절 일자 시작
		state.searchCondition.stockEndDate = ''; // 상품 품절 일자 종료
		state.searchCondition.startStandardPrice = ''; // 상품 기준가 시작
		state.searchCondition.endStandardPrice = ''; // 상품 기준가 종료
		state.searchCondition.partnerShopId = ''; // 도매상
		state.searchCondition.partnerShopName = ''; // 도매상
		state.searchCondition.partnerShopIds = []; // 도매상들
		state.searchCondition.itemStyle = ''; // 스타일
		state.regularStore = '';
	},

	SET_REGULAR_STORE: (state, data) => {
		state.regularStore = data;
	},
};
