import getters from './getters';
import mutations from './mutations';
import actions from './actions';

const state = () => ({
	balanceSum: 0,
	balanceClassAmountMap: {
		정산: 0,
		출고: 0,
		입금: 0,
	},
	balances: [],
	page: 0,
	setPageSize: 10,
	totalPages: 0,
	totalItems: 0,
});

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
