//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import axios from 'axios';
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import html2canvas from 'html2canvas';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { getUserFromCookie } from '@/utils/cookies.js';
export default {
	filters: {
		comma(val) {
			return String(val).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
		},
	},
	async asyncData({ app }) {
		if (process.client) {
			// 클라이언트 사이드에서만 실행
			// 플러그인을 동적으로 로드
			await app.$nuxt.plugin('https://player.vimeo.com/api/player.js', { ssr: false });
		}
	},
	data: () => ({
		showImageCount: 4,
		captureDialog: false,
		isPickLoading: false,
		isRegularLoading: false,
		isShowing: false,
		imgdownDialog: false,
		pickItemBoolean: false,
		pickItemSnackbar: false,
		pickItemMessage: '찜하기',
		regularBoolean: false,
		regularSnackbar: false,
		regularMessage: '관심매장',
		timeout: 2000,

		selectedImageIndex: 0,
		numberItems: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
		selectedOptionSizes: [],
		optionColor: '',
		optionSize: '',
		customerUser: '',
		itemId: 0,
		totalPrice: 0,
		itemList: [],
		itemOptionList: [],
		optionList: [],
		selectItem: [],
	}),
	computed: {
		...mapGetters('product', [
			'isProductDetail',
			'isProductDetailOption',
			'isProductOrderCount',
			'isProductOrderSum',
			'isPickItemBoolean',
			'isRegularBoolean',
		]),
		...mapGetters('auth', ['isUsername']),
		...mapGetters('coupon', ['isCouponOrderApproval']),
		slideImageList() {
			return this.isProductDetail.itemFile.slice(0, 7);
		},
		sortedIsProductDetailOption() {
			// return this.isProductDetailOption.slice().sort((a, b) => a.id - b.id);
			if (this.isProductDetailOption) {
				if (!this.isProductDetail.colorOption || !this.isProductDetail.sizeOption) {
					return ''; // colorOption 또는 sizeOption이 없으면 빈 문자열을 반환
				}
				this.makeOptionArray(
					this.isProductDetail.colorOption.split(','),
					this.isProductDetail.sizeOption.split(','),
				);
				const sortedOptionItems = this.sortOptionItems();
				// console.log('sortedOptionItems', sortedOptionItems);
				return sortedOptionItems;
			} else {
				return '';
			}
		},
		sortedIsAllOption() {
			// 품절 옵션 목록
			if (!this.isProductDetail.colorOption || !this.isProductDetail.sizeOption) {
				return ''; // colorOption 또는 sizeOption이 없으면 빈 문자열을 반환
			}
			this.makeOptionArray(
				this.isProductDetail.colorOption.split(','),
				this.isProductDetail.sizeOption.split(','),
			);
			return this.optionList;
		},
		selectedImage() {
			if (!this.slideImageList) return '';
			const image = this.slideImageList[this.selectedImageIndex];
			return image.url;
		},
		selectedHeaders() {
			if (this.isProductDetail.stock === '품절') {
				return [
					{
						text: '색상/사이즈',
						value: 'color',
						sortable: false,
						width: '100%',
					},
				];
			} else {
				return [
					{
						text: '색상/사이즈',
						value: 'color',
						sortable: false,
						width: '70%',
					},
					{
						text: '수량',
						value: 'itemCount',
						sortable: false,
						align: 'center',
						width: '30%',
					},
				];
			}
		},
	},
	created() {
		this.selectedImageIndex = 0; // 새로운 상품상세이면 첫번째부터 슬라이딩
		this.itemList = [];
	},
	methods: {
		...mapActions('cart', ['addCart']),
		...mapMutations('product', ['SET_PICKITEM_BOOLEAN', 'SET_REGULAR_BOOLEAN']),
		...mapActions('mall', [
			'addPickItem',
			'deletePickItem',
			'addRegularStore',
			'deleteRegularStore',
		]),
		...mapActions('coupon', ['addCouponUseHistory']),
		commaSplit(val) {
			return val.split(',');
		},
		onSlideGroupClick(n) {
			this.selectedImageIndex = n;
		},
		// 소팅 (2023.06.13) Start
		sortOptionItems() {
			// 비동기 작업을 수행하지 않는 정렬 작업 수행
			const baseOption = JSON.parse(JSON.stringify(this.optionList));
			const sortOptionItems = JSON.parse(
				JSON.stringify(this.isProductDetailOption.filter(item => item.active === 'Y')),
			);
			sortOptionItems.sort((itemA, itemB) => {
				const matchingItemA = baseOption.find(
					aItem => aItem.color === itemA.color && aItem.size === itemA.size,
				);
				const matchingItemB = baseOption.find(
					aItem => aItem.color === itemB.color && aItem.size === itemB.size,
				);
				return baseOption.indexOf(matchingItemA) - baseOption.indexOf(matchingItemB);
			});
			return sortOptionItems;
		},
		makeOptionArray(option1, option2) {
			this.optionList = [];
			for (let i = 0; i < option1.length; i++) {
				for (let j = 0; j < option2.length; j++) {
					const newOption = {
						color: option1[i],
						size: option2[j],
						active: 'Y',
						stock: '판매',
						gubun: 'new',
					};
					this.optionList.push(newOption);
				}
			}
			// console.log(this.optionList);
		},
		// 소팅 (2023.06.13) End
		onShowing() {
			// 옵션선택값이 있으면 보여주기
			if (this.itemList.length === 0) {
				this.isShowing = false;
			} else {
				this.isShowing = true;
			}
		},
		onStore() {
			// Product Store 초기화
			this.$store.commit('product/SET_INIT_DATA', '');
			this.$store.commit('product/SET_STOCK', '판매'); // 판매 상품
			const dateParam = { startDate: '', endDate: '' };
			this.$store.commit('product/SET_CREATEDATE', dateParam); // 등록 일자
			const priceParam = { startPrice: '', endPrice: '' };
			this.$store.commit('product/SET_STANDARDPRICE', priceParam); // 기준가
			this.$store.commit('product/SET_ITEMNAME', ''); // 상품명
			this.$store.commit('product/SET_PARTNERSHOPID', this.isProductDetail.shopId); // 도매상
			this.$store.commit('product/SET_PARTNERSHOPNAME', this.isProductDetail.shopName); // 도매상명

			this.$router.push('/shop/StreetProducts');

			this.$emit('closeDialog');
		},
		// 옵션 수량
		increment(index) {
			// items 배열에서 해당 인덱스의 itemCount 값을 1 증가시킵니다.
			const incval = parseInt(this.$refs['cnt' + index].value) + 1;
			if (incval < 100) {
				const requestData = { index, count: incval };
				this.$store.commit('product/SET_OPTION_COUNT', requestData);
			}
		},

		decrement(index) {
			// items 배열에서 해당 인덱스의 itemCount 값을 1 감소시킵니다.
			const decval = parseInt(this.$refs['cnt' + index].value) - 1;
			if (decval >= 0) {
				const requestData = { index, count: decval };
				this.$store.commit('product/SET_OPTION_COUNT', requestData);
			}
		},
		// 품절상품에만 보여주는 옵션 목록 (모든 옵션)
		getOptionList() {
			// const optionList = this.isProductDetail.itemOption.slice().sort((a, b) => a.id - b.id);
			// return optionList.filter(item => item.active === 'Y');
		},
		// 상품 옵션 목록에서 색상값 가져오기
		getOptionListColor() {
			const filteredData = this.isProductDetail.itemOption.filter(item => item.active === 'Y');
			const colors = filteredData.reduce((acc, item) => {
				const existingItem = acc.find(group => group.color === item.color);
				if (existingItem) {
					existingItem.count += 1;
				} else {
					// acc.push({ color: item.color, count: 1 });
					acc.push(item.color);
				}
				return acc;
			}, []);
			return colors;
		},
		// 색상 선택시 색상의 품절 목록 가져오기
		handleColorChange() {
			// 1117463 / 1117647 테스트
			this.optionSize = null; // 사이즈 초기화
			this.selectedOptionSizes = this.isProductDetail.itemOption
				.filter(item => item.active === 'Y' && item.color === this.optionColor)
				.map(item => {
					if (item.stock === '품절') {
						return `${item.size}(${item.stock})`;
					} else {
						return item.size;
					}
				});
		},
		onAddItem() {
			if (this.optionColor === '') {
				alert('색상을 선택하시기 바랍니다.');
				return false;
			}
			if (this.optionSize !== null) {
				if (this.itemList.some(this.checkSameOption)) {
					alert('이미 선택한 옵션입니다.');
					this.optionSize = '';
					return false;
				} else if (this.optionSize.includes('(품절)')) {
					alert('품절인 옵션는 구매하실 수 없습니다.');
					this.handleColorChange();
					this.optionSize = '';
					return false;
				} else {
					const aItem = {
						itemId: this.isProductDetail.id,
						itemColor: this.optionColor,
						itemSize: this.optionSize,
						itemPrice: this.isProductDetail.standardPrice,
						itemCount: 1,
					};
					this.itemList.push(aItem);
				}
				this.onShowing();
				this.$nextTick(() => {
					this.resetCombobox();
				});
			}
		},
		checkSameOption(element) {
			if (element.itemColor === this.optionColor && element.itemSize === this.optionSize) {
				return true;
			}
		},
		reset() {
			this.selectedImageIndex = 0; // 새로운 상품상세이면 첫번째부터 슬라이딩
			this.showImageCount = 4; // 4개만 보여주기
			this.itemList = [];
			this.isShowing = false;
			this.optionColor = '';
			this.optionSize = '';
			// this.$refs.combobox1.blur();
			// this.$refs.combobox2.blur();
		},
		resetCombobox() {
			this.optionColor = '';
			this.optionSize = '';
			// this.$refs.combobox1.blur();
			// this.$refs.combobox2.blur();
		},
		priceTotal(key) {
			const totals = this.itemList.reduce(
				(acc, d) => {
					acc.itemCount += d.itemCount;

					this.totalPrice = parseInt(acc.itemCount) * parseInt(d.itemPrice);
					// console.log('dddd==>', acc.itemCount + '/' + this.totalPrice);
					acc.itemPrice = `${parseInt(acc.itemCount) * parseInt(d.itemPrice)} 원`;
					return acc;
				},
				{
					itemCount: 0,
					itemPrice: '0 원',
				},
			);
			if (key === 'itemCount') {
				return totals.itemCount;
			} else {
				return totals.itemPrice;
			}
		},
		deleteItem(item) {
			const inx = this.itemList.indexOf(item);
			this.itemList.splice(inx, 1);
			this.onShowing();
		},
		// 바로구매
		async directOrder() {
			this.itemList = [];
			this.customerUser = this.isUsername || getUserFromCookie();
			this.itemId = this.isProductDetail.id;

			await this.sortedIsProductDetailOption.forEach(item => {
				if (item.itemCount > 0) {
					this.itemList.push({
						itemId: this.isProductDetail.id,
						itemColor: item.color,
						itemSize: item.size,
						itemPrice: this.isProductDetail.standardPrice,
						itemCount: item.itemCount,
					});
				}
			});

			if (this.itemList.length > 0) {
				// 주문진행 상품 담기
				const selectedOrderItems = [];
				const optionItem = [];
				const image = this.isProductDetail.itemFile[0];
				for (let k = 0; k < this.itemList.length; k++) {
					const aitem = {
						cartItemId: k + 1,
						itemId: this.itemList[k].itemId,
						itemName: this.isProductDetail.itemName,
						firstFile: image.filePath,
						itemColor: this.itemList[k].itemColor,
						itemSize: this.itemList[k].itemSize,
						itemPrice: this.itemList[k].itemPrice,
						itemCount: this.itemList[k].itemCount,
					};
					optionItem.push(aitem);
				}
				const directItem = {
					cartId: 0,
					customerUser: this.customerUser,
					totalPrice: this.isProductOrderSum,
					cartItems: optionItem,
				};
				selectedOrderItems.push(directItem);
				// console.log('selectedOrderItems==>', selectedOrderItems);
				this.itemList.splice(0);
				// this.onShowing();
				this.$emit('closeDialog');

				// 주문 진행 할  상품
				this.$store.commit('cart/SET_ORDER_CARTID', selectedOrderItems);
				this.$router.push('/shop/OrderPage');
			} else {
				alert('옵션에서 수량을 선택하시기 바랍니다.');
			}
		},
		// 장바구니 담기
		async registerCart() {
			this.itemList = [];
			this.customerUser = this.isUsername || getUserFromCookie();
			this.itemId = this.isProductDetail.id;

			await this.sortedIsProductDetailOption.forEach(item => {
				if (item.itemCount > 0) {
					this.itemList.push({
						itemId: this.isProductDetail.id,
						itemColor: item.color,
						itemSize: item.size,
						itemPrice: this.isProductDetail.standardPrice,
						itemCount: item.itemCount,
					});
				}
			});

			if (this.itemList.length > 0) {
				// 새로운 상품 장바구니 담기
				const cartData = {
					customerUser: this.customerUser,
					itemId: this.itemId,
					totalPrice: this.isProductOrderSum,
					itemList: this.itemList,
				};
				// console.log('addCart==>', cartData);
				this.addCart(cartData);
				this.itemList.splice(0);
				// this.onShowing();

				this.$emit('closeDialog');
			} else {
				alert('옵션에서 수량을 선택하시기 바랍니다.');
			}
		},
		imageDownDialog() {
			const params = {
				username: this.isUsername,
				status: '승인',
				page: 0,
				size: 500,
			};
			this.$store.dispatch('coupon/getSearchCouponOrderApproval', params);
			this.imgdownDialog = true;
		},
		saveUseHistory(type) {
			const params = {
				itemId: this.isProductDetail.id,
				downloadClass: type,
			};
			this.addCouponUseHistory(params);
		},
		forceFileDownload(response, title) {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', title);
			document.body.appendChild(link);
			link.click();

			// console.log('forceFileDownload OK');
			this.saveUseHistory('img');
		},
		singleDown(item, seq) {
			const url = item.url;
			const ext = item.savedFileName.split('.').pop();
			const fname = this.isProductDetail.id + '_' + seq + '.' + ext;
			axios({
				method: 'get',
				url,
				responseType: 'arraybuffer',
			})
				.then(response => {
					this.forceFileDownload(response, fname);
				})
				.catch(() => console.log('error occured'));
		},
		async zipDown() {
			const zip = new JSZip(); // ZIP 객체 생성
			const zipfilename = this.isProductDetail.id + '_' + this.isProductDetail.itemName + '.zip';
			for (let i = 0; i < this.isProductDetail.itemFile.length; i++) {
				const aitem = this.isProductDetail.itemFile[i];
				const ext = aitem.savedFileName.split('.').pop();
				const fileName = this.isProductDetail.id + '_' + i + '.' + ext;
				let fileRes;
				let fileBlob;
				if (fileName.length > 1) {
					fileRes = await fetch(aitem.url);
					fileBlob = await fileRes.blob();
					zip.file(fileName, fileBlob);
				}
			}
			zip
				.generateAsync({ type: 'blob' }) // 압축파일 생성
				.then(resZip => {
					FileSaver(resZip, zipfilename); // file-saver 라이브러리 사용
					this.saveUseHistory('zip');
				});
		},
		async onPickItem() {
			this.isPickLoading = true;
			if (this.isPickItemBoolean) {
				// true (찜 취소)
				this.pickItemMessage = '찜하기 취소 되었습니다.';
				await this.deletePickItem(this.isProductDetail.pickItemOneDto.id); // pick id
				this.pickItemBoolean = false;
			} else {
				// false (찜 추가)
				this.pickItemMessage = '찜하기 처리 되었습니다.';
				const param = { itemId: this.isProductDetail.id }; // 상품 코드
				await this.addPickItem(param);
				this.pickItemBoolean = true;
			}
			this.callProductDetailAPI(this.isProductDetail.id);
			this.SET_PICKITEM_BOOLEAN(this.pickItemBoolean);
			this.pickItemSnackbar = true;
			this.isPickLoading = false;
		},
		async onRegularStore() {
			this.isRegularLoading = true;
			if (this.isRegularBoolean) {
				// true (단골 취소)
				this.regularMessage = '단골매장 취소 되었습니다.';
				await this.deleteRegularStore(this.isProductDetail.regularStoreOneDto.id); // regular id
				this.regularBoolean = false;
			} else {
				// false (단골 추가)
				this.regularMessage = '단골매장 처리 되었습니다.';
				const param = { partnerShopId: this.isProductDetail.shopId }; // 도매상 id
				await this.addRegularStore(param);
				this.regularBoolean = true;
			}
			this.callProductDetailAPI(this.isProductDetail.id);
			this.SET_REGULAR_BOOLEAN(this.regularBoolean);
			this.regularSnackbar = true;
			this.isRegularLoading = false;
		},
		async callProductDetailAPI(id) {
			await this.$store.dispatch('product/getProductById', id);
		},
		download(canvas, filename) {
			// create an "off-screen" anchor tag
			const a = document.createElement('a');
			// the key here is to set the download attribute of the a tag
			a.download = filename;
			// convert canvas content to data-uri for link. When download
			// attribute is set the content pointed to by link will be
			// pushed as "download" in HTML5 capable browsers
			a.href = canvas.toDataURL('image/png;base64');
			a.style.display = 'none';
			document.body.appendChild(a);
			a.click();
			document.body.removeChild(a);

			this.captureDialog = false;
		},
		async onScreen() {
			this.captureDialog = true;
			this.showImageCount = 100;
			await this.$nextTick(() => {
				const captureHeight = this.$refs.capture.clientHeight;
				this.$emit('goBottom', captureHeight);
			});
			let waitTime = 700;
			if (this.isProductDetail.itemFile.length > 35) {
				waitTime = 1500;
			}
			setTimeout(() => {
				this.onScreenShotClick();
			}, waitTime);
		},
		onScreenShotClick() {
			const captureWidth = this.$refs.capture.clientWidth;
			let myscale = 1;
			if (captureWidth > 1000) myscale = 0.75;
			html2canvas(this.$refs.capture, {
				logging: true,
				letterRendering: true,
				allowTaint: false,
				useCORS: true,
				// scale: (1920 * 2) / window.innerWidth,
				scale: myscale,
				backgroundColor: null,
			})
				.then(canvas => {
					// this.saveAs(canvas.toDataURL('image/jpg'), 'FastFest.jpg');
					this.download(canvas, this.isProductDetail.id + '.jpg');
				})
				.catch(error => {
					console.log('ERROR SAVING FESTIVAL IMAGE', error);
				});
			// https://medium.com/@fleetw00d/html2canvas-vue-js-90150958c570

			// const element = document.querySelector('#capture');
			// html2canvas(element, {
			// 	proxy: 'http://gimg.b2bsystem.co.kr',
			// 	allowTaint: true,
			// 	userCORS: true,
			// }).then(canvas => {
			// 	this.download(canvas, 'screenshot.jpg');
			// });
		},
	},
};
