//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapMutations } from 'vuex';

export default {
	data() {
		return {
			search: '',
			selection: [],
			open: [],
			allOpened: false,
			lastOpen: [],
			deleteDialog: false,
			deleteItem: {},
			alertMessage: '',
			parentResult: [],
		};
	},
	computed: {
		...mapGetters('category', ['treeCategory', 'listCategory']),
	},
	watch: {
		treeCategory(val) {
			// if (val) {
			this.open = this.treeCategory.children.map(item => item.id);
			// } else {
			// Do stuff when the dialog is closing
			// }
		},
	},

	methods: {
		...mapMutations('app', ['SET_CATEGORY_DIALOG_VISIBLE']),
		...mapMutations('product', ['SET_CATEGORYPATH']),
		handleSearch(val) {
			if (val) {
				if (!this.allOpened) {
					this.lastOpen = this.open;
					this.allOpened = true;
					this.$refs.tree.updateAll(true);
				}
			} else {
				this.$refs.tree.updateAll(false);
				this.allOpened = false;
				this.open = this.lastOpen;
			}
		},
		selectedCategory(item) {
			const categoryPathObject = {
				categoryPath: item.categoryPath,
				categoryPathName: this.getCategoryNameFromJson(item.categoryPath),
			};
			// console.log('*** categoryPathObject ::', categoryPathObject);
			this.$store.commit('product/SET_ALL_INIT_DATA', ''); // 상품 목록 및 페이지, 조건 reset
			this.$store.commit('product/SET_STOCK', '판매');
			// Store 카테고리 값 변경
			this.$store.commit('product/SET_CATEGORYPATH', categoryPathObject);
			// 카테고리별 상품목록 페이지 이동 ==> 상품검색페이지로 이동
			// this.$router.push('/shop/SearchProducts');
			this.$router.push('/shop/CategoryProducts?query=' + item.categoryPath);

			this.dialog = false;
			this.SET_CATEGORY_DIALOG_VISIBLE(false);
		},
		// 카테고리 코드 => 카테고리 이름
		getCategoryNameFromJson(data) {
			const parseData = data.split('/');
			let tempData = '';
			for (let i = 0; i < parseData.length; i++) {
				let rexData = '';
				if (i !== parseData.length - 1) {
					rexData = ' > ';
				}
				tempData += this.parseCategoryPath(parseData[i]) + rexData;
			}
			return tempData;
		},
		parseCategoryPath(data) {
			// console.log('넘어온 데이타 ', data);
			const findData = this.listCategory.filter(it => String(it.id).includes(data));
			return findData[0].name;
		},
		openTreeModal(prouct) {
			console.log('open!!', prouct);
			// this.dialog = true;
			setTimeout(() => {
				this.open = this.treeCatetory.children.map(item => item.id);
			}, 100);
		},
		close() {
			this.SET_CATEGORY_DIALOG_VISIBLE(false);
		},
	},
};
