export const product = axios => ({
	// Product 전체 조회
	fetchAllProducts(params) {
		return axios.get('/', { params });
	},

	// Product 검색
	fetchSearchProducts(params) {
		return axios.get('/api/retailer/items/search', { params });
	},

	// Id 조회
	fetchProductById(id) {
		return axios.get(`/api/retailer/items/${id}`);
	},

	// Id promotion 조회
	fetchProductPromotionById(id) {
		return axios.get(`/api/retailer/items/promotion/flyer/${id}`);
	},

	// item Best  검색
	fetchSearchItemBests(params) {
		return axios.get('/api/retailer/bests/search', { params });
	},

	// item Sale  검색
	fetchSearchItemSales(params) {
		return axios.get('/api/retailer/sales/search', { params });
	},
});
