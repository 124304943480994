import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']

let store = {};

(function updateModules () {
  // If store is an exported method = classic mode (deprecated)

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('..\\src\\store\\product\\index.js'), 'product/index.js')
  resolveStoreModules(require('..\\src\\store\\post\\index.js'), 'post/index.js')
  resolveStoreModules(require('..\\src\\store\\order\\index.js'), 'order/index.js')
  resolveStoreModules(require('..\\src\\store\\mall\\index.js'), 'mall/index.js')
  resolveStoreModules(require('..\\src\\store\\coupon\\index.js'), 'coupon/index.js')
  resolveStoreModules(require('..\\src\\store\\code\\index.js'), 'code/index.js')
  resolveStoreModules(require('..\\src\\store\\category\\index.js'), 'category/index.js')
  resolveStoreModules(require('..\\src\\store\\cart\\index.js'), 'cart/index.js')
  resolveStoreModules(require('..\\src\\store\\balance\\index.js'), 'balance/index.js')
  resolveStoreModules(require('..\\src\\store\\auth\\index.js'), 'auth/index.js')
  resolveStoreModules(require('..\\src\\store\\app\\index.js'), 'app/index.js')
  resolveStoreModules(require('..\\src\\store\\app\\actions.js'), 'app/actions.js')
  resolveStoreModules(require('..\\src\\store\\app\\getters.js'), 'app/getters.js')
  resolveStoreModules(require('..\\src\\store\\app\\mutations.js'), 'app/mutations.js')
  resolveStoreModules(require('..\\src\\store\\auth\\actions.js'), 'auth/actions.js')
  resolveStoreModules(require('..\\src\\store\\auth\\getters.js'), 'auth/getters.js')
  resolveStoreModules(require('..\\src\\store\\auth\\mutations.js'), 'auth/mutations.js')
  resolveStoreModules(require('..\\src\\store\\balance\\actions.js'), 'balance/actions.js')
  resolveStoreModules(require('..\\src\\store\\balance\\getters.js'), 'balance/getters.js')
  resolveStoreModules(require('..\\src\\store\\balance\\mutations.js'), 'balance/mutations.js')
  resolveStoreModules(require('..\\src\\store\\cart\\actions.js'), 'cart/actions.js')
  resolveStoreModules(require('..\\src\\store\\cart\\getters.js'), 'cart/getters.js')
  resolveStoreModules(require('..\\src\\store\\cart\\mutations.js'), 'cart/mutations.js')
  resolveStoreModules(require('..\\src\\store\\category\\actions.js'), 'category/actions.js')
  resolveStoreModules(require('..\\src\\store\\category\\getters.js'), 'category/getters.js')
  resolveStoreModules(require('..\\src\\store\\category\\mutations.js'), 'category/mutations.js')
  resolveStoreModules(require('..\\src\\store\\code\\actions.js'), 'code/actions.js')
  resolveStoreModules(require('..\\src\\store\\code\\getters.js'), 'code/getters.js')
  resolveStoreModules(require('..\\src\\store\\code\\mutations.js'), 'code/mutations.js')
  resolveStoreModules(require('..\\src\\store\\coupon\\actions.js'), 'coupon/actions.js')
  resolveStoreModules(require('..\\src\\store\\coupon\\getters.js'), 'coupon/getters.js')
  resolveStoreModules(require('..\\src\\store\\coupon\\mutations.js'), 'coupon/mutations.js')
  resolveStoreModules(require('..\\src\\store\\mall\\actions.js'), 'mall/actions.js')
  resolveStoreModules(require('..\\src\\store\\mall\\getters.js'), 'mall/getters.js')
  resolveStoreModules(require('..\\src\\store\\mall\\mutations.js'), 'mall/mutations.js')
  resolveStoreModules(require('..\\src\\store\\order\\actions.js'), 'order/actions.js')
  resolveStoreModules(require('..\\src\\store\\order\\getters.js'), 'order/getters.js')
  resolveStoreModules(require('..\\src\\store\\order\\mutations.js'), 'order/mutations.js')
  resolveStoreModules(require('..\\src\\store\\post\\actions.js'), 'post/actions.js')
  resolveStoreModules(require('..\\src\\store\\post\\getters.js'), 'post/getters.js')
  resolveStoreModules(require('..\\src\\store\\post\\mutations.js'), 'post/mutations.js')
  resolveStoreModules(require('..\\src\\store\\product\\actions.js'), 'product/actions.js')
  resolveStoreModules(require('..\\src\\store\\product\\getters.js'), 'product/getters.js')
  resolveStoreModules(require('..\\src\\store\\product\\mutations.js'), 'product/mutations.js')

  // If the environment supports hot reloading...
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function normalizeRoot (moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule (moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    console.warn(`'state' should be a method that returns an object in ${filePath}`)

    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function resolveStoreModules (moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const propertyStoreModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(propertyStoreModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeState (moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    console.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function getStoreModule (storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty (storeModule, moduleData, property) {
  if (!moduleData) {
    return
  }

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}
