export default {
	// Order 목록 Actions
	async getSearchOrder({ commit }, param) {
		try {
			const { data } = await this.$orderApi.fetchSearchOrders(param);
			commit('SET_DATA', data);
		} catch (error) {}
	},

	// Order 저장
	async addOrder({ commit }, param) {
		try {
			const { data } = await this.$orderApi.registerOrderByBody(param);
			commit('ADD_ORDER', data);
		} catch (error) {}
	},

	// Order 취소
	async deleteOrder({ commit }, param) {
		try {
			await this.$orderApi.deleteOrderByBody(param);
			// commit('DELETE_ORDER', param);
		} catch (error) {}
	},

	// 대량 주문 조회 OrderMethod 대량
	async getSearchOrderBulk({ commit }, param) {
		try {
			const { data } = await this.$orderApi.fetchSearchOrders(param);
			commit('SET_BULK_DATA', data);
		} catch (error) {}
	},
};
