export default {
	// balances 목록
	SET_DATA: (state, data) => {
		state.balances = data.data.sort((a, b) => new Date(b.balanceDate) - new Date(a.balanceDate));
		state.page = data.page.currentPage;
		state.totalPages = data.page.totalPages;
		state.totalItems = data.page.totalItems;
	},

	// 합계 조회
	SET_SUM_DATA: (state, data) => {
		// console.log('데이타 >> ', data);
		state.balanceSum = data.balanceAmountSum;
		state.balanceClassAmountMap.정산 = data.balanceClassAmountMap.정산;
		state.balanceClassAmountMap.출고 = data.balanceClassAmountMap.출고;
		state.balanceClassAmountMap.입금 = data.balanceClassAmountMap.입금;
	},
};
