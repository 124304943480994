export default {
	onBalance({ balances }) {
		return balances;
	},
	// 합계
	getBalanceSum({ balanceSum }) {
		return balanceSum;
	},
	getBalanceClassAmountMap({ balanceClassAmountMap }) {
		return balanceClassAmountMap;
	},
};
