export default {
	// 마켓이용권 주문 내역
	isCoupons(state) {
		return state.coupons;
	},
	// 마켓이용권 주문 내역
	isCouponOrder(state) {
		return state.couponOrder;
	},
	// 주문 승인 enddate < 오늘
	isCouponOrderApproval(state) {
		return state.couponOrderApproval;
	},
	// 이미지 다운로드 내역
	isCouponUseHistory(state) {
		return state.couponUseHistory;
	},
	isTotalPages(state) {
		return state.totalPages;
	},
	isPageSize(state) {
		return state.setPageSize;
	},
	isTotalItems(state) {
		return state.totalItems;
	},
	// 마켓이용권 구분
	isLabels(state) {
		return state.labels;
	},
};
