// import { category } from '../../../api';
// import state from './state';

/*
|---------------------------------------------------------------------
| TODO Vuex Mutations
|---------------------------------------------------------------------
*/
export default {
	SET_DATA: (state, data) => {
		// console.log('Mutations에 넘어온 Data', data)
		state.categories = data.listData;
		state.categoryTree = data.treeData;
	},

	SET_MODAL_DATA: (state, data) => {
		// console.log('Mutations에 넘어온 Data', data)

		state.categoryModal = data;
	},

	SET_TREE_DATA: (state, data) => {
		// console.log('Mutations에 넘어온 Tree Category Data', data);
		state.categoryTree = data;
	},

	UPDATE_LIST_CATEGORY: (state, category) => {
		const categoryIdx = state.categories.find(t => t.id === category.id);

		Object.assign(categoryIdx, category);
	},

	UPDATE_TREE_CATEGORY: (state, category) => {
		console.log('mutation에 내려온 데이타?', category);
		console.log('categoryTree 데이타', state.categoryTree);

		// const categoryIdx = state.categoryTree.find((t) => t.id === category.id)
		// Object.assign(categoryIdx, category)
	},
};
